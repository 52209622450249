import { useEffect, useState } from 'react';
import './index.css'
export default function PrivacyPolicy() {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 19) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <div className="terms-and-conditions mt-[78px]">
    <h1>PRIVACY POLICY</h1>
    <br/>
    <h3>Last updated May 01, 2023</h3>
    <br/>
    <br/>
      <p>
      This privacy notice for TECHDCRYPT SERVICES (OPC) PRIVATE LIMITED ('<span className='bolding-letters'>we</span>', '<span className='bolding-letters'>us</span>', or '<span className='bolding-letters'>our</span>'), describes how and why we might collect, store, use, and/or share ('<span className='bolding-letters'>process</span>') your information when you use our services ('<span className='bolding-letters'>Services</span>'), such as when you:
      </p>
      <ul>
        <li className='list-style-item'>Visit our website at <a href="https://prepifai.com"><span style={{color:"blue",textDecoration:"underline"}}>https://prepifai.com</span></a> or any website of ours that links to this privacy notice</li>
        <li className='list-style-item'>Engage with us in other related ways, including any sales, marketing, or events</li>
      </ul>
      <p>
      <span className='bolding-letters'>Questions or concerns?</span> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at admin@prepifai.com.
      </p>
      <br/>
      <br/>
      <h1>SUMMARY OF KEY POINTS</h1>
      <p>
      <span className='bolding-letters'>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <a href="#table"><span style={{color:"blue"}} className='underlining-content'>table of contents</span></a> below to find the section you are looking for.</span>
      </p>
      <br/>
      <p>
      <span className='bolding-letters'>What personal information do we process?</span> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <a href='#PROCESS-YOUR-INFORMATION'><span style={{color:"blue"}} className='underlining-content'>personal information you disclose to us.</span></a>       </p>
      <br/>
      <p>
      <span className='bolding-letters'>Do we process any sensitive personal information?</span> We do not process sensitive personal information.      </p>
      <br/>
      <p>
      <span className='bolding-letters'>Do we receive any information from third parties?</span> We do not receive any information from third parties.      </p>
      
<p><span className='bolding-letters'>How do we process your information?</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <a href="#WE-SHARE-YOUR-PERSONAL-INFORMATION"><span style={{color:"blue"}} className='underlining-content'>how we process your information.</span></a></p>
<p><span className='bolding-letters'>In what situations and with which parties do we share personal information?</span> We may share information in specific situations and with specific third parties. Learn more about <a href="#WE-SHARE-YOUR-PERSONAL-INFORMATION"><span style={{color:"blue"}} className='underlining-content'>when and with whom we share your personal information. </span></a></p>
<p><span className='bolding-letters'>How do we keep your information safe?</span> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <a href="#KEEP-YOUR-INFORMATION-SAFE"><span style={{color:"blue"}}  className='underlining-content'>how we keep your information safe.</span></a></p>
<p><span className='bolding-letters'>What are your rights?</span> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <a href="#YOUR-PRIVACY-RIGHTS"><span style={{color:"blue"}} className='underlining-content'>your privacy rights.</span></a></p>
<p><span className='bolding-letters'>How do you exercise your rights?</span> The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
<p><span className='bolding-letters'>Want to learn more about what we do with any information we collect?</span> <span style={{color:"blue",textDecoration:"underline"}}><a href="#table">Review the privacy notice in full.</a></span></p>
      <br/>
      
    <section id="table">
      <h2>Table of Contents</h2>
      <ol className="table-of-contents">
        <li className='list-style-item'><a href="#INFORMATION-DO-WE-COLLECT">1. WHAT INFORMATION DO WE COLLECT?</a></li>
        <li className='list-style-item'><a href="#PROCESS-YOUR-INFORMATION">2. HOW DO WE PROCESS YOUR INFORMATION?</a></li>
        <li className='list-style-item'><a href="#WE-SHARE-YOUR-PERSONAL-INFORMATION">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
        <li className='list-style-item'><a href="#TRACKING-TECHNOLOGIES">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
        <li className='list-style-item'><a href="#SOCIAL-LOGINS">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
        <li className='list-style-item'><a href="#WE-KEEP-YOUR-INFORMATION">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
        <li className='list-style-item'><a href="#KEEP-YOUR-INFORMATION-SAFE">7. How Do We Keep Your Information Safe?</a></li>
        <li className='list-style-item'><a href="#INFORMATION-FROM-MINORS">8. DO WE COLLECT INFORMATION FROM MINORS?</a></li>
        <li className='list-style-item'><a href="#YOUR-PRIVACY-RIGHTS">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
        <li className='list-style-item'><a href="#DO-NOT-TRACK-FEATURES">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
        <li className='list-style-item'><a href="#UPDATES-TO-THIS-NOTICE">11. DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
        <li className='list-style-item'><a href="#CONTACT-US-ABOUT-THIS-NOTICE">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
        <li className='list-style-item'><a href="#DELETE-THE-DATA-WE-COLLECT-FROM-YOU">13. How Can You Review, Update, or Delete the Data We Collect from You?</a></li>
      </ol>
    </section>
    <br/>

    <div>
      <section id="INFORMATION-DO-WE-COLLECT">
      <h2>1. What Information Do We Collect?</h2>
      <p><strong>Personal information you disclose to us</strong></p>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> We collect personal information that you provide to us.</p>
      <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
      <p><strong><span className='bolding-letters'>Personal Information Provided by You:</span></strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
      <ul>
        <li className='list-style-item'>names</li>
        <li className='list-style-item'>email addresses</li>
        <li className='list-style-item'>mailing addresses</li>
      </ul>
      <p><strong><span className='bolding-letters'>Sensitive Information:</span></strong> We do not process sensitive information.</p>
      <p><strong><span className='bolding-letters'>Payment Data:</span></strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Phonepe. You may find their privacy notice link(s) here: <a href="https://www.phonepe.com/privacy-policy"><span style={{color:"blue"}} className='underlining-content'>https://www.phonepe.com/privacy-policy</span></a>.</p>
      <p><strong><span className='bolding-letters'>Social Media Login Data:</span></strong> We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called <a href=""><span style={{color:"blue"}} className='underlining-content'>'HOW DO WE HANDLE YOUR SOCIAL LOGINS?'</span></a> below.</p>
      <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>  </section>
      <section id="PROCESS-YOUR-INFORMATION">
      <h2>2. How Do We Process Your Information?</h2>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.</p>
      <p><span className='bolding-letters'>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span></p>
      <ul>
        <li className='list-style-item'><span className='bolding-letters'>To facilitate account creation and authentication and otherwise manage user accounts.</span>We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
        <li className='list-style-item'><span className='bolding-letters'>To deliver and facilitate delivery of services to the user.</span> We may process your information to provide you with the requested service.</li>
        <li className='list-style-item'><span className='bolding-letters'>To respond to user inquiries/offer support to users.</span> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
        <li className='list-style-item'><span className='bolding-letters'>To send administrative information to you.</span> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
        <li className='list-style-item'><span className='bolding-letters'>To fulfill and manage your orders.</span> We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.</li>
        <li className='list-style-item'><span className='bolding-letters'>To enable user-to-user communications.</span> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
        <li className='list-style-item'><span className='bolding-letters'>To request feedback.</span>  We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
        <li className='list-style-item'><span className='bolding-letters'>To send you marketing and promotional communications.</span> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see <a href=""><span style={{color:"blue"}} className='underlining-content'>'WHAT ARE YOUR PRIVACY RIGHTS?'</span></a> below.</li>
        <li className='list-style-item'><span className='bolding-letters'>To deliver targeted advertising to you.</span> We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.</li>
        <li className='list-style-item'><span className='bolding-letters'>To post testimonials.</span> We post testimonials on our Services that may contain personal information.</li>
        <li className='list-style-item'><span className='bolding-letters'>To protect our Services.</span> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
        <li className='list-style-item'><span className='bolding-letters'>To administer prize draws and competitions.</span> We may process your information to administer prize draws and competitions.</li>
        <li className='list-style-item'><span className='bolding-letters'>To evaluate and improve our Services, products, marketing, and your experience.</span> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</li>
        <li className='list-style-item'><span className='bolding-letters'>To identify usage trends.</span> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
        <li className='list-style-item'><span className='bolding-letters'>To determine the effectiveness of our marketing and promotional campaigns.</span> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
        <li className='list-style-item'><span className='bolding-letters'>To comply with our legal obligations.</span> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
      </ul>
      </section>
      <section id="WE-SHARE-YOUR-PERSONAL-INFORMATION">
      <h2>3. When and With Whom Do We Share Your Personal Information?</h2>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> We may share information in specific situations described in this section and/or with the following third parties.</p>
      <p>We may need to share your personal information in the following situations:</p>
      <ol>
        <li className='list-style-item'><strong><span className='bolding-letters'>Business Transfers:</span></strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li className='list-style-item'><strong><span className='bolding-letters'>When we use Google Analytics:</span></strong> We may share your information with Google Analytics to track and analyze the use of the Services. To opt out of being tracked by Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout"><span style={{color:"blue"}} className='underlining-content'>https://tools.google.com/dlpage/gaoptout</span></a>. For more information on the privacy practices of Google, please visit the <a href="https://policies.google.com/privacy"><span style={{color:"blue"}} className='underlining-content'>Google Privacy & Terms page.</span></a></li>
        <li className='list-style-item'><strong><span className='bolding-letters'>Business Partners:</span></strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
      </ol> </section>
    <section id="TRACKING-TECHNOLOGIES">
    <h2>4. Do We Use Cookies and Other Tracking Technologies?</h2>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> We may use cookies and other tracking technologies to collect and store your information.</p>
      <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p> </section>
    <section id="SOCIAL-LOGINS">
    <h2>5. How Do We Handle Your Social Logins?</h2>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</p>
      <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</p>
      <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
   </section>
    <section id="WE-KEEP-YOUR-INFORMATION">
    <h2>6. How Long Do We Keep Your Information?</h2>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</p>
      <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than twelve (12) months past the termination of the user's account.</p>
      <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
      </section>
    <section id="KEEP-YOUR-INFORMATION-SAFE">
    <h2>7. How Do We Keep Your Information Safe?</h2>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> We aim to protect your personal information through a system of organisational and technical security measures.</p>
      <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
    </section>
    <section id="INFORMATION-FROM-MINORS">
      <h2>8. DO WE COLLECT INFORMATION FROM MINORS?</h2>
      <p><span className='bolding-letters'>In Short:</span>We do not knowingly collect data from or market to children under 18 years of age.</p>
      <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at admin@prepifai.com.</p>
    </section>
    <section id="YOUR-PRIVACY-RIGHTS">
    <h2>9. What Are Your Privacy Rights?</h2>
      <p><strong><span className='bolding-letters'>In Short:</span></strong> You may review, change, or terminate your account at any time.</p>
      <p><strong><span className='bolding-letters'>Withdrawing your consent:</span></strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section<a href=""><span style={{color:"blue"}} className='underlining-content'> 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' </span></a> below.</p>
      <p><strong><span className='bolding-letters'>Opting out of marketing and promotional communications:</span></strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section <a href=""><span style={{color:"blue"}} className='underlining-content'>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</span></a> below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
      <h3>Account Information</h3>
      <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
      <ul>
        <li className='list-style-item'>Contact us using the contact information provided.</li>
      </ul>
      <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
      <p>If you have questions or comments about your privacy rights, you may email us at admin@prepifai.com.</p>  </section>
    <section id="DO-NOT-TRACK-FEATURES">
    <h2>10. Controls for Do-Not-Track Features</h2>
      <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
</section>
<section id="UPDATES-TO-THIS-NOTICE">
<h2>11. Do We Make Updates to This Notice?</h2>
      <p><span className='bolding-letters'>In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
      <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
</section>
<section id="CONTACT-US-ABOUT-THIS-NOTICE">
<h2>12. How Can You Contact Us About This Notice?</h2>
      <p>If you have questions or comments about this notice, you may email us at <a href="mailto:admin@prepifai.com">admin@prepifai.com</a> or contact us by post at:</p>
      <address>
        <strong>TECHDCRYPT SERVICES (OPC) PRIVATE LIMITED</strong><br />
        C/O DINESH PRASAD SRIVASTAVA, SADHU CHOWK, SAREYA, GOPALGANJ GOPALGANJ<br />
        Gopalganj, Bihar 841420<br />
        India
      </address>

</section>
<section id="DELETE-THE-DATA-WE-COLLECT-FROM-YOU">
<h2>13. How Can You Review, Update, or Delete the Data We Collect from You?</h2>
      <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us at <a href="mailto:admin@prepifai.com">admin@prepifai.com</a>.</p>
</section>
    </div>
  </div>
  </>
  );
}