import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PaymentSnackbar = ({ snackbarStatusMessage, open, handleClosePaymentSnackbar }) => {
  return (
    <Snackbar
      message={snackbarStatusMessage}
      key="top center"
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={10000}
      onClose={handleClosePaymentSnackbar}>
      <Alert
        onClose={handleClosePaymentSnackbar}
        severity={snackbarStatusMessage === "Payment successful" ? "success" : "error"}
        sx={{ width: "100%" }}>
        {/* {MESSAGES[message]} */}
        {snackbarStatusMessage}
      </Alert>
    </Snackbar>
  );
};

export default PaymentSnackbar;
