export default function Support() {
  let businessName = "TECHDCRYPT SERVICES (OPC) PRIVATE LIMITED";
  let address = "C/O DINESH PRASAD SRIVASTAVA, SADHU CHOWK, SAREYA, GOPALGANJ GOPALGANJ";
  let phone = "+91 8826907483";
  let email = "admin.prepifai.com";
  return (
    <div>
      <h1>
        Contact {businessName}: Find below customer service details of {businessName}, including phone and email.
      </h1>
      <div>
        <h2>Address</h2>
        <h3>{address}</h3>
      </div>
      <div>
        <h2>Customer Service</h2>
        <h3>Phone: {phone}</h3>
        <h3>Email: {email}</h3>
      </div>
    </div>
  );
}
