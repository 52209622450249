import { Interweave } from "interweave";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Typography, List, ListItem, ListItemButton, ListItemText, Collapse, CardActions } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Option from "./Option";
import { useState } from "react";

// incase more options than 4, defensive handling
const choicesList = ["A", "B", "C", "D", "E", "F", "G"];

const StyledListItemButton = styled(ListItem)(({ listtitle }) => ({
  "&::before": {
    content: `"${listtitle}"`,
    position: "absolute",
    top: "-18px",
    left: "5px",
    padding: "",
    textAlign: "center",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return (
    <Typography paragraph color="text.secondary" sx={{ marginLeft: "8px" }}>
      Detailed Solution
      <IconButton {...other} />
    </Typography>
  );
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DisplayQuestionCard = ({ parentExam, subject, data, handleOptionSelect, submitted, defaultExpand }) => {
  // question data
  const { question, options, correctChoiceIndex, selectedOption, detailed_solution, yearAsked } = data;
  const [expanded, setExpanded] = useState(defaultExpand);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card variant="outlined" sx={{ mb: 1 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14, textAlign: "start" }} color="text.secondary" gutterBottom>
          {`${yearAsked ? yearAsked : ""} `}
          {parentExam} - {subject}
        </Typography>
        <Typography variant="h6" component="div" sx={{ textAlign: "start" }} className="mb-3">
          <Interweave content={question}></Interweave>
        </Typography>
        <List>
          {options.map((option, index) => {
            let outlineText = "";
            let backgroundColorAfterSubmit = "";
            if (submitted) {
              if (index === selectedOption && correctChoiceIndex === selectedOption) {
                outlineText = "Selected & Correct Answer";
                backgroundColorAfterSubmit = "bg-green-200";
              } else if (index === selectedOption && correctChoiceIndex !== selectedOption) {
                outlineText = "You Selected";
                backgroundColorAfterSubmit = "bg-red-200";
              } else if (index === correctChoiceIndex) {
                outlineText = "Correct Answer";
                backgroundColorAfterSubmit = "bg-green-200";
              } else if (index === selectedOption) {
                outlineText = "Selected Answer";
                backgroundColorAfterSubmit = "bg-sky-50";
              } else if (index === correctChoiceIndex && selectedOption === -1) {
                outlineText = "Not Attempted Correct Answer";
                backgroundColorAfterSubmit = "bg-green-200";
              }
            } else if (index === selectedOption) {
              backgroundColorAfterSubmit = "bg-sky-50";
            }
            return (
              <StyledListItemButton
                disablePadding
                key={option}
                className={`${backgroundColorAfterSubmit} ${index === options.length - 1 ? "mb-0" : "mb-3"} py-1`}
                onClick={handleOptionSelect}
                data-optionindex={index}
                listtitle={outlineText}>
                <ListItemButton>
                  <Option alphabet={choicesList[index]} selected={index === selectedOption ? true : false} />
                  <ListItemText primary={<Interweave content={option} />} />
                </ListItemButton>
              </StyledListItemButton>
            );
          })}
        </List>
      </CardContent>
      {submitted ? (
        <>
          <CardActions disableSpacing>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                <Interweave content={detailed_solution}></Interweave>
              </Typography>
            </CardContent>
          </Collapse>
        </>
      ) : null}
    </Card>
  );
};

export default DisplayQuestionCard;
