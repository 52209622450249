import * as React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent, DialogContentText, IconButton } from "@mui/material";

const BuyPromptDialog = ({ showBuyPrompt, startExam, handlePaymentModalOpen, handleBuyPromptDialogClose }) => {
  return (
    <Dialog
      onClose={() => {
        handleBuyPromptDialogClose();
        startExam();
      }}
      open={showBuyPrompt}
      keepMounted
      aria-labelledby="prompt to buy"
      aria-describedby="click on buy to get access to all the questions">
      <DialogTitle>
        Practice like a Topper! 👑
        <IconButton
          aria-label="close"
          onClick={() => {
            handleBuyPromptDialogClose();
            startExam();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This is only a free limited set. Click Buy for personalized practice from 12,000+ questions.</DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Disagree</Button> */}
        <Button
          onClick={() => {
            handlePaymentModalOpen();
            handleBuyPromptDialogClose();
          }}
          autoFocus>
          Buy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyPromptDialog;
