import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://prepifai.com/aiims-pg-pyq-previous-year-questions">
        PrepifAI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "#fff5f8",
      }}>
      <Container maxWidth="sm">
        <Typography variant="body1" sx={{ mb: "1rem" }}>
          Contact us at{" "}
          <Link href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=admin@prepifai.com" target="_blank" rel="noreferrer">
            admin@prepifai.com
          </Link>
        </Typography>
        <Typography variant="body1" sx={{ mb: "1rem" }}>
          Telegram Group:{" "}
          <Link href="https://t.me/prepifai" target="_blank" rel="noreferrer">
            https://t.me/prepifai
          </Link>
        </Typography>

        <Typography variant="body1" sx={{ mb: "1rem" }}>
          Telegram Channel:{" "}
          <Link href="https://t.me/prepifai_channel" target="_blank" rel="noreferrer">
            https://t.me/prepifai_channel
          </Link>
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
}
