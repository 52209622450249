import * as React from "react";
import { pay, paymentStatus } from "../../utils";
import { Link, Button, Typography, Box, Modal, Stack, IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import { isBrowser, isMobile, isIOS } from "react-device-detect";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationExit from "./ConfirmationExit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const PaymentScreen = ({
  paymentModalOpen,
  handleCloseNestedPaymentModal,
  handleOpenNestedPaymentModal,
  payData,
  loaderPayModal,
  handleCloseNestedPaymentModalOnly,
  paymentModalNestedOpen,
}) => {
  return (
    <Dialog style={{}} maxWidth="xs" open={paymentModalOpen} keepMounted>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOpenNestedPaymentModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        {/* {!isIOS ? (
          <IconButton
            aria-label="back"
            onClick={handleOpenNestedPaymentModal}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <ArrowBackIosNewIcon />
          </IconButton>
        ) : null} */}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Alert severity="warning" sx={{ mb: 3 }}>
            Please don't close or refresh while payment is processing. It will close automatically.
          </Alert>
          <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={5}>
            <img src="/images/meme.jpg" alt="img" width="150px" height="80px" />
            <Typography id="pay-modal-title" variant="h5" component="h2" sx={{ textAlign: "center" }}>
              Pay ₹ 399 (6 months)
            </Typography>
            {isMobile ? (
              <Button variant="contained" style={{ marginTop: "20px" }}>
                <a href={`${payData.intentUrl}`} alt="mobile UPI payment link" style={{ color: "white" }}>
                  Click here to pay with UPI App
                </a>
              </Button>
            ) : null}
          </Stack>

          <Typography id="pay-modal-description" sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            {isMobile ? "Or " : null} Scan the code to pay
          </Typography>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
            {!isMobile ? (
              <img src={`data:image/jpeg;base64,${payData.qrData}`} alt="" style={{ width: "270px", height: "270px" }} />
            ) : (
              <img src={`data:image/jpeg;base64,${payData.qrData}`} alt="payment qr" style={{ width: "300px", height: "300px" }} />
            )}
          </Stack>
        </Box>
      </DialogContent>
      <ConfirmationExit
        closeAllModals={handleCloseNestedPaymentModal}
        handleCloseNestedPaymentModalOnly={handleCloseNestedPaymentModalOnly}
        paymentModalNestedOpen={paymentModalNestedOpen}
      />
    </Dialog>
  );
};

export default PaymentScreen;
