import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login/Login";
import UserContext from "./UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProtectedRoutes from "./Components/ProtectedRoutes";
// import BannerContext from "./bannerContext";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import SidebarContext from "./sidebarContext";
import PYQFastTrack from "./Components/PYQFastTrack/PYQFastTrackDashboard";
import QuestionsDashboard from "./Components/Questions/QuestionsDashboard";
import Support from "./Components/Support/Support";
import TermsOfService from "./Components/TermsOfService/TermsOfService";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Cancellation from "./Components/Cancellation/Cancellation";
import BannerContext from "./bannerContext";
// import ProfileDashboard from "./Components/Profile/ProfileDashboard";

function App() {
  const userState = useState({});
  const sidebarContext = useState({});
  // const bannerContext = useState({ showBanner: true });

  const responseFromGoogleLogin = (data) => {
    // setLoggedInUser(data);
  };

  localStorage.setItem("showBanner", true);

  const bannerContext = useState({ showBanner: localStorage.getItem("showBanner") });

  return (
    <GoogleOAuthProvider clientId="553125461546-ti2e6ul3g3n5ik3ohm2fr7ojoqebrlhq.apps.googleusercontent.com">
      <UserContext.Provider value={userState}>
        <SidebarContext.Provider value={sidebarContext}>
          <BannerContext.Provider value={bannerContext}>
            <Router>
              <CssBaseline />
              <Routes>
                <Route exact path="/login" element={<Login responseFromGoogleLogin={responseFromGoogleLogin} />}></Route>
                {[
                  "/",
                  "/aiimspgpyqpreviousyearquestions",
                  "/aiims-pg-pyq-previous-year-questions",
                  "/aiimspgpyqpreviousyearquestions/:slug",
                  "/aiims-pg-pyq-previous-year-questions/:slug",
                  "/neet-pg-pyq-previous-year-questions/:slug",
                  "/neetpgpyqpreviousyearquestions",
                  "/neet-pg-pyq-previous-year-questions",
                  "/neetpgpyqpreviousyearquestions/:slug",
                ].map((path) => {
                  return <Route exact path={path} key={path} element={<PYQFastTrack responseFromGoogleLogin={responseFromGoogleLogin} />}></Route>;
                })}
                {/* <Route path="/questions/:slug" element={<QuestionsDashboard responseFromGoogleLogin={responseFromGoogleLogin} />}></Route> */}
                <Route path="/support" element={<Support />}></Route>
                <Route path="/terms-of-service" element={<TermsOfService />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="/cancellation" element={<Cancellation />}></Route>
                {/* <Route
                  exact
                  path="/profile"
                  element={
                    <ProtectedRoutes Component={ProfileDashboard} path="/profile" responseFromGoogleLogin={responseFromGoogleLogin} />
                  }></Route> */}
                <Route path="/questions/:slug" element={<QuestionsDashboard responseFromGoogleLogin={responseFromGoogleLogin} />}></Route>
              </Routes>
            </Router>
          </BannerContext.Provider>
        </SidebarContext.Provider>
      </UserContext.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
