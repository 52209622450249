import * as React from "react";
import { Box, Button, Typography, Modal, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Submit = styled(Button)(({ theme }) => ({
  color: "#042f2e",
  backgroundColor: "#fca5a5",
  "&:hover": {
    backgroundColor: "#f87171",
  },
}));

const Cancel = styled(Button)(({ theme }) => ({
  color: "#042f2e",
  backgroundColor: "#86efac",
  "&:hover": {
    backgroundColor: "#4ade80",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#616161",
  backgroundColor: "#e11d48",
  "&:hover": {
    backgroundColor: "#be123c",
  },
}));

const FinishExamModal = ({ handleSubmitExam }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <ColorButton onClick={handleOpen} variant="contained" sx={{ color: "white", p: 2 }}>
        Finish Exam
      </ColorButton>
      <Modal open={open} onClose={handleClose} aria-labelledby="Finish exam" aria-describedby="Click on the finish exam button to submit the exam">
        <Box sx={style}>
          <Typography id="finish-exam-title" variant="h6" component="h2" sx={{ mb: 3 }}>
            Are you sure you want to submit?
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <Cancel onClick={handleClose}>Cancel</Cancel>
            <Submit onClick={handleSubmitExam}>Finish Exam</Submit>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default FinishExamModal;
