import { useEffect, useState, useRef, useContext, useMemo } from "react";
import {
  useTheme,
  Divider,
  useFormControl,
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Button,
  Box,
  TextField,
  Typography,
  styled,
  Grid,
  Paper,
  Container,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Select,
  Autocomplete,
  CardActions,
  CardContent,
  IconButton,
} from "@mui/material";
import LeftPane from "../LeftPane/LeftPane";
import axios from "axios";
import configData from "../../config.json";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import DOMPurify from "dompurify";
import UserContext from "../../UserContext";
import Cookies from "js-cookie";
import BootstrapDialogTitle from "../Modals/BootstrapDialogTitle";
import BootstrapDialog from "../Modals/Dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import StickyFooter from "../StickyFooter/StickyFooter";
import _ from "lodash";
import PYQFastTrackCard from "./PYQFastTrackCard";
import DayDisplay from "./DayDisplay";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import SubmissionStats from "./SubmissionStats";
import SidebarContext from "../../sidebarContext";
import FinishExamModal from "./FinishExamModal";
import BuyPromptDialog from "../Payment/BuyPromptDialog";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#616161",
  backgroundColor: "#e11d48",
  "&:hover": {
    backgroundColor: "#be123c",
  },
}));

const CycleButton = styled(Button)(({ theme }) => ({
  color: "#042f2e",
  backgroundColor: "#22c55e",
  "&:hover": {
    backgroundColor: "#16a34a",
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
// get data about which exams to show from config
// let examsList = Object.keys(configData["exams"]).reduce((list, exam) => {
//   let examDetail = configData["exams"][exam];
//   if (examDetail.pyq_enabled) {
//     list.push(examDetail.name);
//   }
//   return list;
// }, []);
// examsList.splice(examsList.indexOf("UPSC IAS"), 1);
// examsList.unshift("UPSC IAS");
// temp change only showing UPSC

// UPSC IAS
// modal on submit answers
// are you sure you want to submit
// userid questionid true/false
// static default value in select exam dropdown for now
let examsList = ["AIIMS PG", "NEET PG"];
// const selectExamList = examsList.map((exam) => {
//   return <MenuItem value={exam}>{exam}</MenuItem>;
//   // return { label: exam, id: exam };
// });

// campaign start date
// format YYYY/MM/DD
const START_DATE_FOR_EACH_EXAM = {
  "UPSC IAS": "2023-04-08",
  "UPPSC PCS": "2023-04-10",
};

const NUMBER_OF_DAYS_FOR_EACH_EXAM = {
  "UPSC IAS": 44,
  "UPPSC PCS": 30,
};

// const START_DATE = "2023-04-08";
// Number of wrong questions to show
const NUMBER_OF_WRONG_QUESTIONS = 5;

const PYQFastTrackDashboard = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);

  useEffect(() => {
    isLoggedIn();
    // let path = location.pathname.split("/")[1];
    // setSidebarData()
    // console.log("****");
  }, []);

  const isLoggedIn = () => {
    let name = Cookies.get("user_name");
    let user_id = Cookies.get("user_id");
    let email = Cookies.get("email");

    if (name && user_id && email) {
      let apiData = {
        name: name,
        user_id: user_id,
        email: email,
        domain: "prepifai",
      };

      internalLogin(apiData, undefined, false);
    }
  };

  const internalLogin = (data, userInfo, setCookie = false) => {
    let config = {
      method: "post",
      url: "https://prepifai.com/api/user/login_via_sso",
      headers: {
        token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        // match user_if from response with the data stored in cookie

        if (response.data.user_id === data.user_id) {
          setLoggedInUserDetails(
            Object.assign({}, JSON.parse(Cookies.get("google_info")), { user_id: data.user_id, premium: response.data.premium })
          );
        }
      })
      .catch((error) => {});
  };
  // TODO: for @Saurabh clean this up
  // make different google analytics id
  return (
    <>
      <Box sx={{ display: "flex", ml: 4, flexDirection: "column", minHeight: "100vh" }}>
        <Helmet>
          <title>AIIMS PG INICET PYQ Previous Year Questions</title>
          <meta name="keywords" content="neet pg ini cet neet pg exam neet pg neet exam exam for aiims neet pg 2022 question paper" />
          <meta
            name="description"
            content="Master your AIIMS PG Exam preparation with our comprehensive collection of previous year questions, expertly curated solutions, and personalized progress tracking. Unlock your potential and boost your confidence for AIIMS PG success today!"
          />

          <meta property="og:title" content="AIIMS PG Exam Prep: Previous Year Questions, Solutions & Success Strategies" />
          <meta property="og:url" content="https://prepifai/aiims-pg-pyq-previous-year-questions" />
          <meta
            property="og:description"
            content="Master your AIIMS PG Exam preparation with our comprehensive collection of previous year questions, expertly curated solutions, and personalized progress tracking. Unlock your potential and boost your confidence for AIIMS PG success today!"
          />
          <meta property="og:site_name" content="Prepif AI" />
        </Helmet>
        <LeftPane Component={PYQFastTrack} />
      </Box>
      <StickyFooter />
    </>
  );
};

let subjects = [
  "Anaesthesia",
  "Anatomy",
  "Biochemistry",
  "Dermatology",
  "ENT",
  "Forensic Medicine",
  "Medicine",
  "Microbiology",
  "Obstetrics and Gynaecology",
  "Ophthalmology",
  "Orthopaedics",
  "PSM",
  "Paediatrics",
  "Pathology",
  "Pharmacology",
  "Physiology",
  "Psychiatry",
  "Radiology",
  "Surgery",
];

const NUMBER_OF_QUESTIONS_LIST = [10, 20, 30, 40, 50];

const PYQFastTrack = ({ handlePaymentModalOpen, setShowMobileFABMenu, handleShowBannerPremium }) => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  let something = useParams();
  let location = useLocation();

  // this will stay like this if no slug is there
  let initialSelectedExamState = location.pathname.indexOf("aiims") > -1 ? "AIIMS PG" : "NEET PG";

  // if (slug) {
  //   // take value from slug
  //   initialSelectedExamState = slug.split("-").join(" ").toUpperCase();
  // }
  const [openLoginPrompt, setLoginPrompt] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("PYQ Fast Track");
  const [language, setLanguage] = useState("English");
  const [selectedExam, setSelectedExam] = useState(initialSelectedExamState);
  const [questions, setQuestions] = useState([]);
  const [wrongQuestions, setWrongQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [disableLanguageSelect, setDisableLanguageSelect] = useState(false);
  const [selectedDayIndex, setSelectedDayIndex] = useState(
    dayjs(dayjs().format("YYYY/MM/DD")).diff(dayjs(START_DATE_FOR_EACH_EXAM[selectedExam]), "day")
  );
  const disabledDate = dayjs(dayjs().format("YYYY/MM/DD")).diff(dayjs(START_DATE_FOR_EACH_EXAM[selectedExam]), "day");
  // PLEASE REFACTOR THE NUMBER OF DAYS
  const [numberOfDays, setNumberOfDays] = useState(NUMBER_OF_DAYS_FOR_EACH_EXAM[selectedExam]);
  const [examMode, setExamMode] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [examSubmitted, setExamSubmitted] = useState(false);
  const [subject, setSubject] = useState(subjects[0]);
  const [showBuyPrompt, setShowBuyPrompt] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(NUMBER_OF_QUESTIONS_LIST[0]);
  const [sidebarData, setSidebarData] = useContext(SidebarContext);
  const [submissionStats, setSubmissionStats] = useState({
    correctAnswers: null,
    wrongAnswers: null,
    unAttemptedAnswers: null,
    repeatedCorrectAnswers: null,
    repeatedWrongAnswers: null,
  });
  const [questionIndex, setQuestionIndex] = useState(0);

  const theme = useTheme();

  let navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    // navigate(`/pyqexplorer/${event.target.value.split(" ").join("s-").toLowerCase()}-previous-year-question-pyq-explorer`);
    navigate(`/${selectedExam.split(" ").join("-").toLowerCase()}-pyq-previous-year-questions`);
  }, [selectedExam]);

  const handleLoginButtonPrompt = () => {
    navigate("/login", { state: { from: window.location.pathname } });
  };

  const handleLoginPromptOpen = () => {
    setLoginPrompt(true);
  };

  const handleLoginPromptClose = () => {
    setLoginPrompt(false);
  };

  const handleLanguageChange = (event) => {
    setSubject(event.target.value);
  };

  const handleNumberOfQuestionsChange = (event) => {
    setNumberOfQuestions(event.target.value);
  };

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const startExam = async () => {
    try {
      setQuestionsLoading(true);
      let body = { user_id: loggedInUserDetails.user_id, parentExam: selectedExam, subject: subject };

      // get all user responses and filter out those markes as incorrect, we will use these incorrect marked questions to show too
      // but put these questions separate, there needs to be clear indication that these incorrect questions are different
      let allUserResponses = await axios.post("https://prepifai.com/api/user/get_user_question_responses", body, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        },
      });

      allUserResponses = allUserResponses.data;
      let wrongAnswersID = allUserResponses.reduce((wrongAnswersID, data) => {
        if (!data.is_correct) {
          wrongAnswersID.push([data.question_id, data.exam_name]);
        }

        return wrongAnswersID;
      }, []);

      // pick only the defined NUMBER_OF_WRONG_QUESTIONS wrong questions
      let idk = wrongAnswersID.slice();
      // console.log(allWrongIDS);
      wrongAnswersID = wrongAnswersID.slice(0, NUMBER_OF_WRONG_QUESTIONS);
      let allWrongIDS = [];

      for (let something of idk) {
        allWrongIDS.push(something[0]);
      }
      let allWrongAnswers = [];

      for (let wrongAnswerId of wrongAnswersID) {
        body = {
          user_id: loggedInUserDetails.user_id,
          parentExam: wrongAnswerId[1],
          question_id: wrongAnswerId[0],
          subject: subject,
        };

        let data = await axios.post("https://prepifai.com/api/pyq/scheduled", body, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
          },
        });

        data.data.questions[0]["selectedOption"] = -1;
        allWrongAnswers.push(data.data.questions[0]);
      }

      body = {
        user_id: loggedInUserDetails.user_id,
        parentExam: selectedExam,
        subject: subject,
        max_questions: numberOfQuestions,
        // day: selectedDayIndex + 1,
      };
      // get questions for a selected day
      let response = await axios.post("https://prepifai.com/api/pyq/scheduled", body, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        },
      });

      // modify object inside array to track user selections
      let responseQuestions = [];
      for (let index = 0; index < response.data.questions.length; index++) {
        // dont show wrong answers at the top, show them separately
        if (allWrongIDS.indexOf(response.data.questions[index]["_id"]) > -1) {
          continue;
        }
        let temp = _.cloneDeep(response.data.questions[index]);
        temp["selectedOption"] = -1;
        // set to -1 as we dont want any option selected on initial render
        // now keep modifying this according to the index of the selected option
        responseQuestions.push(temp);
      }

      setQuestions(responseQuestions);
      setWrongQuestions(allWrongAnswers);
      setQuestionsLoading(false);
      setSelectedDayIndex(0);
      setExamMode(true);
      setShowMobileFABMenu(false);
    } catch (error) {
      setQuestionsLoading(false);
      setExamMode(false);
      setShowMobileFABMenu(true);
      setSelectedDayIndex(0);
    }
  };

  const handleStartExam = async (event) => {
    if (loggedInUserDetails.name) {
      if (!loggedInUserDetails.premium) {
        setShowBuyPrompt(true);
      } else {
        startExam();
      }
    } else {
      setSelectedDayIndex(0);
      handleLoginPromptOpen();
    }
  };

  const handleSubmitExam = async (event) => {
    if (loggedInUserDetails.name) {
      try {
        let correctAnswers = 0;
        let wrongAnswers = 0;
        let unAttemptedAnswers = 0;
        let repeatedCorrectAnswers = 0;
        let repeatedWrongAnswers = 0;

        setSubmitLoader(true);
        let userAttemptedQuestionsData = [];
        // concat questions and wrongQuestions
        for (let iterate = 0; iterate < questions.length; iterate++) {
          // -1 means user didnt attempt/select that option so dont send those when submitting
          let question = _.cloneDeep(questions[iterate]);
          let selectedOption = Number(question["selectedOption"]);
          if (selectedOption !== -1) {
            userAttemptedQuestionsData.push(question);
          }

          // tracking answers stats
          if (Number(question["correctChoiceIndex"]) === selectedOption && selectedOption !== -1) {
            correctAnswers++;
          } else if (Number(question["correctChoiceIndex"]) !== selectedOption && selectedOption !== -1) {
            wrongAnswers++;
          } else if (selectedOption === -1) {
            unAttemptedAnswers++;
          }
        }
        for (let iterate = 0; iterate < wrongQuestions.length; iterate++) {
          let question = _.cloneDeep(wrongQuestions[iterate]);
          let selectedOption = Number(question["selectedOption"]);
          // -1 means user didnt attempt/select that option so dont send those when submitting
          if (selectedOption !== -1) {
            userAttemptedQuestionsData.push(question);
          }

          // tracking answers stats
          if (Number(question["correctChoiceIndex"]) === selectedOption && selectedOption !== -1) {
            correctAnswers++;
            repeatedCorrectAnswers++;
          } else if (Number(question["correctChoiceIndex"]) !== selectedOption && selectedOption !== -1) {
            wrongAnswers++;
            repeatedWrongAnswers++;
          } else if (selectedOption === -1) {
            unAttemptedAnswers++;
          }
        }

        setSubmissionStats({
          correctAnswers: correctAnswers,
          wrongAnswers: wrongAnswers,
          unAttemptedAnswers: unAttemptedAnswers,
          repeatedCorrectAnswers: repeatedCorrectAnswers,
          repeatedWrongAnswers: repeatedWrongAnswers,
        });

        // sending request
        let body = {};
        for (let questionData of userAttemptedQuestionsData) {
          let selectedOption = Number(questionData.selectedOption);
          let isCorrect = selectedOption === Number(questionData.correctChoiceIndex);
          body = {
            user_id: loggedInUserDetails.user_id,
            question_id: questionData._id,
            exam_name: selectedExam,
            user_response_index: selectedOption,
            is_correct: isCorrect,
          };

          await axios.post("https://prepifai.com/api/user/user_question_response", body, {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
            },
          });
        }

        setSubmitLoader(false);
        setExamSubmitted(true);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        setSubmitLoader(false);
      }
    } else {
      handleLoginPromptOpen();
    }
  };

  const handleExamChange = (event) => {
    setSelectedExam(event.target.value);
  };

  const handleDayChange = (event) => {
    let disable = event.target.dataset.disablePointer === "disable" || event.target.dataset.disablePointer === undefined;
    if (disable) {
      return;
    }
    setSelectedDayIndex(Number(event.target.dataset.index));
  };

  const modifySelectedOptionFromState = (questionArr, cardIndex, selectedOption) => {
    let tempQuestionsState = new Array(questionArr.length);
    for (let index = 0; index < questionArr.length; index++) {
      tempQuestionsState[index] = questionArr[index];

      if (index === Number(cardIndex)) {
        tempQuestionsState[index]["selectedOption"] = Number(selectedOption);
      }
    }

    return tempQuestionsState;
  };

  const handleOptionSelect = (event) => {
    if (examSubmitted) {
      return;
    }
    if (loggedInUserDetails.name) {
      let cardIndex = Number(event.currentTarget.dataset.cardindex);
      let selectedOption = Number(event.currentTarget.dataset.optionindex);
      let isWrongMarkedQuestion = event.currentTarget.dataset.iswrongmarkedquestion === "true" ? true : false;
      let tempQuestionsState;
      if (isWrongMarkedQuestion) {
        tempQuestionsState = modifySelectedOptionFromState(wrongQuestions, cardIndex - questions.length, selectedOption);
        setWrongQuestions(tempQuestionsState);
      } else {
        tempQuestionsState = modifySelectedOptionFromState(questions, cardIndex, selectedOption);
        setQuestions(tempQuestionsState);
      }
    } else {
      handleLoginPromptOpen();
    }
  };

  const handleReset = () => {
    if (loggedInUserDetails.name) {
      setExamSubmitted(false);
      setQuestions([]);
      setWrongQuestions([]);
      setShowMobileFABMenu(true);
      setExamMode(false);
    } else {
      openLoginPrompt();
    }
  };

  const handleNextQuestion = (event) => {
    // setQuestionIndex((prevQuestionIndex) => setQuestionIndex(prevQuestionIndex + 1));
    setQuestionIndex((questionIndex + 1) % (questions.length + wrongQuestions.length));
  };
  const handlePrevQuestion = (event) => {
    setQuestionIndex((questionIndex + questions.length + wrongQuestions.length - 1) % (questions.length + wrongQuestions.length));
  };

  const handleBuyPromptDialogClose = (event) => {
    setShowBuyPrompt(false);
  };

  //   PYQ FastTrack
  // -  Exam mode: 25 scheduled Qs + 5 wronged questions
  // -  Correct answers and API calls on submission
  // -  Day wise horizontal bar(scrollable) ref: examarly
  return (
    <Box component="main" sx={{ mt: 6, ml: 1, mr: 2, mb: 4 }} display="flex" justifyContent="center">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
        <Grid item xs={12} align="center" justify="center" alignItems="center" sx={{ justifySelf: "start" }}>
          <BuyPromptDialog
            showBuyPrompt={showBuyPrompt}
            handlePaymentModalOpen={handlePaymentModalOpen}
            handleBuyPromptDialogClose={handleBuyPromptDialogClose}
            startExam={startExam}
          />
          <Typography variant="h1" sx={{ fontSize: "3rem" }}>
            {selectedExam + " PYQ"}
          </Typography>
          {!examMode ? (
            <>
              {/* <Typography variant="h6" sx={{ mb: 3 }} style={{ fontSize: "16px" }} gutterBottom={true}>
                Attempt "Mini Prelims" everyday before appearing for Prelims 2023 with our PYQ Fast Track Initiative
              </Typography> */}
              <Typography variant="h6" sx={{ mb: 1 }} style={{ fontSize: "16px" }} gutterBottom={true}></Typography>

              {/* <s */}
              <table>
                <thead>
                  <tr>
                    <th>Features:-</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>★ Solve from our exhaustive list of 12,000+ questions</td>
                  </tr>
                  <tr>
                    <td>★ Choose your own topic and start the exam</td>
                  </tr>
                  <tr>
                    <td>★ Personalized Practice and Tracking: Repetition of questions which you mark wrong.</td>
                  </tr>
                  <tr>
                    <td>★ Detailed answers with explanations</td>
                  </tr>
                  <tr>
                    <td>★ It's free to try🥂</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : null}
        </Grid>
        {
          examMode && examSubmitted ? (
            <Grid item xs={12} align="center" justify="center" alignItems="center">
              <ColorButton onClick={handleReset} variant="contained" sx={{ color: "white", p: 2 }}>
                Menu
              </ColorButton>
            </Grid>
          ) : examMode ? null : null // </Grid> //   </ColorButton> //     Finish Exam //   <ColorButton onClick={handleSubmitExam} variant="contained" sx={{ color: "white", p: 2 }}> // <Grid item xs={12} align="center" justify="center" alignItems="center">
        }
        {!examMode ? (
          <>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
              <FormControl>
                <InputLabel id="exam-select">Select Exam</InputLabel>
                <Select
                  labelId="exam-select"
                  id="select-exam"
                  value={selectedExam}
                  label="Select Exam"
                  onChange={handleExamChange}
                  sx={{ width: 300 }}>
                  {examsList.map((exam, index) => {
                    return (
                      <MenuItem value={exam} key={index}>
                        {exam}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Choose subject</InputLabel>
                <Select
                  disabled={disableLanguageSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subject}
                  label="Choose subject"
                  onChange={handleLanguageChange}
                  defaultValue={"Anaesthesia"}
                  sx={{ width: 300 }}>
                  {subjects.map((subject) => {
                    return (
                      <MenuItem key={subject} value={subject}>
                        {subject}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
              <HtmlTooltip
                title={
                  !loggedInUserDetails.premium ? (
                    <>
                      <Typography color="inherit">Buy the subscription so that you can select the number of questions</Typography>
                      {/* <em>{"And here's"}</em> <b>{"some"}</b> <u>{"amazing content"}</u>. {"It's very engaging. Right?"} */}
                    </>
                  ) : null
                }
                enterTouchDelay={0}
                placement="top">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Number of Questions</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={numberOfQuestions}
                    label="Number of Questions"
                    onChange={handleNumberOfQuestionsChange}
                    sx={{ width: 300 }}
                    disabled={!loggedInUserDetails.premium}>
                    {NUMBER_OF_QUESTIONS_LIST.map((questions) => {
                      return (
                        <MenuItem key={questions} value={questions}>
                          {String(questions)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </HtmlTooltip>
            </Grid>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
              <ColorButton onClick={handleStartExam} variant="contained" sx={{ color: "white", p: 2 }}>
                Start Exam
              </ColorButton>
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} align="center" justify="center" alignItems="center">
          {questionsLoading || submitLoader ? (
            <Stack direction="column" justifyContent="center" alignItems="center">
              <CircularProgress color="info" />
            </Stack>
          ) : (
            <>
              {examSubmitted ? <SubmissionStats submissionStats={submissionStats} /> : null}
              <Paper elevation={0} spacing={2}>
                {examMode === true && examSubmitted === false && questions.length > 0 ? (
                  questionIndex < questions.length ? (
                    <>
                      <PYQFastTrackCard
                        data={questions[questionIndex]}
                        selectedQuestion={selectedQuestions[questionIndex]}
                        key={questions[questionIndex].question}
                        cardIndex={questionIndex}
                        handleOptionSelect={handleOptionSelect}
                        isWrongMarkedQuestion={false}
                        examSubmitted={examSubmitted}
                        defaultExpand={false}
                        totalQuestions={questions.length + wrongQuestions.length}
                        isRepeated={null}
                      />
                      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <CycleButton onClick={handlePrevQuestion} variant="contained" sx={{ color: "white", p: 2, width: "9rem" }}>
                          Previous
                        </CycleButton>
                        <CycleButton onClick={handleNextQuestion} variant="contained" sx={{ color: "white", p: 2, width: "9rem" }}>
                          Next
                        </CycleButton>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <PYQFastTrackCard
                        data={wrongQuestions[questionIndex - questions.length]}
                        selectedQuestion={selectedQuestions[questionIndex]}
                        key={wrongQuestions[questionIndex - questions.length].question}
                        cardIndex={questionIndex}
                        handleOptionSelect={handleOptionSelect}
                        isWrongMarkedQuestion={true}
                        examSubmitted={examSubmitted}
                        defaultExpand={false}
                        totalQuestions={questions.length + wrongQuestions.length}
                        isRepeated={true}
                      />
                      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <CycleButton onClick={handlePrevQuestion} variant="contained" sx={{ color: "white", p: 2, width: "9rem" }}>
                          Previous
                        </CycleButton>
                        <CycleButton onClick={handleNextQuestion} variant="contained" sx={{ color: "white", p: 2, width: "9rem" }}>
                          Next
                        </CycleButton>
                      </Stack>
                    </>
                  )
                ) : null}
                {examMode === true && examSubmitted === true && questions.length > 0
                  ? questions.map((question, index) => {
                      return (
                        <PYQFastTrackCard
                          data={question}
                          selectedQuestion={selectedQuestions[index]}
                          key={question.question}
                          cardIndex={index}
                          handleOptionSelect={handleOptionSelect}
                          isWrongMarkedQuestion={false}
                          examSubmitted={examSubmitted}
                          defaultExpand={false}
                          totalQuestions={questions.length + wrongQuestions.length}
                        />
                      );
                    })
                  : null}
                {examMode === true && examSubmitted === true && wrongQuestions.length > 0
                  ? wrongQuestions.map((question, index) => {
                      return (
                        <PYQFastTrackCard
                          data={question}
                          selectedQuestion={selectedQuestions[index]}
                          key={question.question}
                          cardIndex={index + questions.length}
                          handleOptionSelect={handleOptionSelect}
                          isWrongMarkedQuestion={true}
                          examSubmitted={examSubmitted}
                          defaultExpand={false}
                          totalQuestions={questions.length + wrongQuestions.length}
                        />
                      );
                    })
                  : null}

                {/* {wrongQuestions.length > 0 ? (
                  <Typography variant="h5" className="mb-2 mt-4">
                    Repeated questions for you
                  </Typography>
                ) : null} */}
                {/* {wrongQuestions.map((question, index) => {
                  return (
                    <PYQFastTrackCard
                      data={question}
                      key={question.question}
                      cardIndex={index}
                      isWrongMarkedQuestion={true}
                      handleOptionSelect={handleOptionSelect}
                      examSubmitted={examSubmitted}
                      defaultExpand={false}
                    />
                  );
                })} */}
              </Paper>
            </>
          )}
        </Grid>
        {examMode && examSubmitted ? (
          <Grid item xs={12} align="center" justify="center" alignItems="center">
            <ColorButton onClick={handleReset} variant="contained" sx={{ color: "white", p: 2 }}>
              Menu
            </ColorButton>
          </Grid>
        ) : examMode ? (
          <Grid item xs={12} align="center" justify="center" alignItems="center">
            <FinishExamModal handleSubmitExam={handleSubmitExam} />
          </Grid>
        ) : null}
      </Grid>
      <BootstrapDialog onClose={handleLoginPromptClose} aria-labelledby="login prompt" open={openLoginPrompt}>
        <BootstrapDialogTitle id="login with your google account" onClose={handleLoginPromptClose}>
          Login for this feature
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Login to get access to this feature and many more...</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleLoginButtonPrompt}>
            Login
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};

export default PYQFastTrackDashboard;
