import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  CardActions,
  Stack,
  Link,
} from "@mui/material";

const SubmissionStats = ({ submissionStats }) => {
  let { correctAnswers, wrongAnswers, repeatedCorrectAnswers, repeatedWrongAnswers, unAttemptedAnswers } = submissionStats;
  return (
    <Card variant="outlined" sx={{ mb: 1 }}>
      <CardContent>
        <Typography sx={{ fontSize: 20, textAlign: "center" }} color="text.primary" gutterBottom>
          Submission Stats
        </Typography>
        <Stack spacing={2} sx={{ textAlign: "start", ml: 1 }}>
          <Typography>Correct Answers: {correctAnswers}</Typography>
          <Typography>Wrong Answers: {wrongAnswers}</Typography>
          <Typography>Not Attempted: {unAttemptedAnswers}</Typography>
          <Stack direction="row" spacing={2}>
            <img
              src="/images/telegram.png"
              alt="main logo"
              width="70px"
              height="150px"
              style={{ height: "30px", width: "auto", alignSelf: "center" }}
            />
            <Stack spacing={1}>
              <Typography variant="body1" sx={{ mb: "1rem" }}>
                Telegram Group:{" "}
                <Link href="https://t.me/prepifai" target="_blank" rel="noreferrer">
                  https://t.me/prepifai
                </Link>
              </Typography>

              <Typography variant="body1" sx={{ mb: "1rem" }}>
                Telegram Channel:{" "}
                <Link href="https://t.me/prepifai_channel" target="_blank" rel="noreferrer">
                  https://t.me/prepifai_channel
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SubmissionStats;
