export default function Cancellation() {
  let phone = "+91 8826907483";
  let email = "admin.prepifai.com";
  return (
    <div>
      <h1>Return and Cancellation policy</h1>
      <p>Last Updated April 22,2023</p>
      <h2>Refunds</h2>
      <h3>All sales are final and no refund will be issued</h3>
      <h2>Cancellation</h2>
      <h3>We do not provide cancellations to our service once availed</h3>
      <h2>Questions</h2>
      <h3>We provide free trial so please use that and decide to make the purchase based on that.</h3>
      <h3>If you have any questions concerning our return policy, please contact us at: </h3>
      <p>{phone}</p>
      <p>{email}</p>
    </div>
  );
}
