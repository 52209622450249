import * as React from "react";
import { Box, Modal, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import UserContext from "../../UserContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ErrorButton = styled(Button)(({ theme }) => ({
  color: "#042f2e",
  backgroundColor: "#fca5a5",
  "&:hover": {
    backgroundColor: "#f87171",
  },
}));

const Submit = styled(Button)(({ theme }) => ({
  color: "#042f2e",
  backgroundColor: "#86efac",
  "&:hover": {
    backgroundColor: "#4ade80",
  },
}));

const Cancel = styled(Button)(({ theme }) => ({
  color: "#042f2e",
  backgroundColor: "#fca5a5",
  "&:hover": {
    backgroundColor: "#f87171",
  },
}));

export default function ReportQuestion({ questionId, parentExam }) {
  const [open, setOpen] = React.useState(false);
  const [loggedInUserDetails, setLoggedInUserDetails] = React.useContext(UserContext);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleReportQuestion = async (event) => {
    try {
      let body = {
        parentExam: parentExam,
        user_id: loggedInUserDetails.user_id,
        question_id: questionId,
      };
      console.log(body);
      await axios.post("https://prepifai.com/api/pyq/report_question", body, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        },
      });
    } catch (error) {
      console.log(event);
    } finally {
      handleClose();
    }
  };

  return (
    <div>
      <ErrorButton style={{ fontSize: "14px" }} onClick={handleOpen}>
        Report Question
      </ErrorButton>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={{ ...style, width: 400 }}>
          <Typography sx={{ fontSize: 20, textAlign: "start", mb: 3 }} gutterBottom>
            We take a lot of care in verifying our questions but sometimes we make mistakes. Please click on the Report button to report the mistake
            and we will correct it as soon as possible.
          </Typography>
          {/* <Typography sx={{ fontSize: 18, textAlign: "start" }} gutterBottom>
            Click on 
          </Typography> */}

          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <Cancel onClick={handleClose}>Cancel</Cancel>
            <Submit onClick={handleReportQuestion}>Report</Submit>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
