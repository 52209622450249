import "./DayDisplay.css";
import { useRef, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// color pallete for this
// https://coolors.co/2f2d2e-41292c-792359-d72483-fd3e81
const ScrollButton = styled(IconButton)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#616161",
  "&:hover": {
    backgroundColor: "#212121",
    scale: "1.05",
  },
}));

const DayDisplay = ({ numberOfDays, handleDayChange, selectedDayIndex, disabledDate, selectedExam }) => {
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      // scrollRef.current.scrollLeft = 500;
      scrollRef.current.scrollLeft = 0;
      scrollRef.current.scrollLeft = 40 * (selectedDayIndex + 1);
    }
  }, [selectedExam, selectedDayIndex]);
  const scrollRight = () => {
    scrollRef.current.scrollLeft += 40;
  };

  const scrollLeft = () => {
    scrollRef.current.scrollLeft -= 40;
  };

  return (
    <div className="flex items-center justify-center">
      <ScrollButton variant="contained" onClick={scrollLeft} size={`${!matchesMD ? "large" : "small"}`} className="flex-1">
        <ChevronLeftIcon />
      </ScrollButton>
      <ul className="list flex-0 shadow-md" ref={scrollRef} onClick={handleDayChange} style={{ width: `${matchesMD ? "200px" : "300px"}` }}>
        {new Array(numberOfDays).fill(0).map((elem, index) => {
          return (
            <li
              className={`item ${
                index === selectedDayIndex ? "selected-day" : index > disabledDate && selectedExam !== "UPPSC PCS" ? "disabled-day" : "bg-zinc-900 "
              }`}
              key={index}
              data-index={index}
              data-disable-pointer={`${selectedExam === "UPPSC PCS" ? "active" : index > disabledDate ? "disable" : "active"}`}
              // style={{ pointerEvents: `${index > selectedDayIndex ? "none" : ""}` }}
            >
              {index + 1}
            </li>
          );
        })}
      </ul>
      {/* <li className="item scale-110 bg-pink-500">41</li> */}
      <ScrollButton className="flex-1" variant="contained" onClick={scrollRight} size={`${!matchesMD ? "large" : "small"}`}>
        <ChevronRightIcon />
      </ScrollButton>
    </div>
  );
};

export default DayDisplay;
