import { useEffect, useState, useContext } from "react";
import * as React from "react";
import "./LeftPane.css";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import UserContext from "../../UserContext";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Avatar, DialogActions, DialogContent, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import HeaderBanner from "../HeaderBanner/HeaderBanner";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { Container } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import SidebarContext from "../../sidebarContext";
import NavigationIcon from "@mui/icons-material/Navigation";
import MoreIcon from "@mui/icons-material/MoreVert";
import Fab from "@mui/material/Fab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import { isBrowser, isMobile } from "react-device-detect";
import SpeedIcon from "@mui/icons-material/Speed";
import PaymentDialogParent from "../Payment/PaymentModal";
import { pay, paymentStatus } from "../../utils";
import PaymentSnackbar from "../Payment/PaymentSnackbar";
import BootstrapDialogTitle from "../Modals/BootstrapDialogTitle";
import BootstrapDialog from "../Modals/Dialog";
import _ from "lodash";
import { isIOS } from "react-device-detect";
import BannerContext from "../../bannerContext";
// import BannerContext from "../../bannerContext";

const drawerWidth = 220;
const selectedIconColor = "#0f6fff";
const selectedTabColor = "#0288d1";
const unselectedIconColor = "#616161";

const sidebarIconMap = new Map();
sidebarIconMap.set("Calendar", <CalendarTodayIcon />);
sidebarIconMap.set("Saved for Later", <BookmarkBorderIcon />);
sidebarIconMap.set("Add Content", <AddIcon />);
sidebarIconMap.set("PYQ Explorer", <MenuBookIcon />);
sidebarIconMap.set("Home", <HomeIcon />);
sidebarIconMap.set("PYQ Interview", <PsychologyIcon />);
sidebarIconMap.set("Mock Interview", <CoPresentIcon />);

// ● PHONEPE
// ● GPAY
// ● PAYTM
const LeftPane = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [sidebarData, setSidebarData] = useContext(SidebarContext);
  const [bannerData, setBannerData] = useContext(BannerContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [showSidebarInMobile, setShowSidebarInMobile] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentModalNestedOpen, setPaymentModalNestedOpen] = useState(false);
  const [payData, setPayData] = useState({});
  const [loaderPayModal, setLoaderPayModal] = useState(false);
  const [intervalIds, setIntervalIds] = useState([]);
  const [showPaymentSnackbar, setShowPaymentSnackbar] = useState(false);
  const [openLoginPrompt, setLoginPrompt] = useState(false);
  const [snackbarStatusMessage, setSnackbarStatusMessage] = useState("");
  const [showUPISelectScreen, setShowUPISelectScreen] = useState(false);
  const [selectedUPIApp, setSelectedUPIApp] = useState(null);
  const [showMobileFABMenu, setShowMobileFABMenu] = useState(true);

  const handleClosePaymentSnackbar = () => {
    setShowPaymentSnackbar(false);
  };

  const handleOpenUPISelectScreen = () => {
    setShowUPISelectScreen(true);
  };

  const handleCloseUPISelectScreen = () => {
    setShowUPISelectScreen(false);
    setSelectedUPIApp(null);
  };

  const handleOpenPaymentSnackbar = () => {
    setShowPaymentSnackbar(true);
  };

  const handleLoginPromptClose = () => {
    setLoginPrompt(false);
  };

  const handleLoginPromptOpen = () => {
    setLoginPrompt(true);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { Component } = props;
  let location = useLocation();
  let navigate = useNavigate();
  // const handleRemoveBanner = () => {
  //   console.log("I got clicked");
  //   setBannerData({
  //     showBanner: false,
  //   });
  // };

  const handleDrawerIconNavigate = (index) => (event) => {
    let navigateTo = event.currentTarget.dataset.text.split(" ").join("").toLowerCase();
    if (navigateTo === "mockinterview") {
      navigateTo = "upsc-ias-mock-interview";
    } else if (navigateTo === "aiims-pg-pyq-previous-year-questions" || navigateTo === "aiimspgpyqpreviousyearquestions") {
      navigateTo = "aiims-pg-pyq-previous-year-questions";
    }
    navigate("/" + navigateTo, { replace: true });
    setSidebarData({
      selectedIndex: Number(event.currentTarget.dataset.index),
    });
    setShowSidebarInMobile(false);
  };

  const handleProfileMenuClick = (event) => {
    navigate("/profile");
    handleMenuClose();
  };

  useEffect(() => {
    let path = location.pathname.split("/")[1];

    if (path === "home") {
      // setSidebarData({ selectedIndex: 0 });
    } else if (path === "pyqexplorer") {
      // setSidebarData({ selectedIndex: 1 });
    } else if (path === "pyqinterview") {
      // setSidebarData({ selectedIndex: 2 });
    } else if (path === "upsc-ias-mock-interview") {
      // setSidebarData({ selectedIndex: 3 });
    } else if (path === "calendar") {
      // setSidebarData({ selectedIndex: 4 });
    } else if (path === "savedforlater") {
      // setSidebarData({ selectedIndex: 5 });
    } else if (path === "" || path === "aiims-pg-pyq-previous-year-questions" || path === "aiimspgpyqpreviousyearquestions") {
      setSidebarData({ selectedIndex: 0 });
    } else if (path === "" || path === "neet-pg-pyq-previous-year-questions" || path === "neetpgpyqpreviousyearquestions") {
      setSidebarData({ selectedIndex: 1 });
    }
    // console.log(bannerData.showBanner);
  }, []);

  const handleLoginButton = () => {
    navigate("/login", { state: { from: window.location.pathname } });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (event) => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logout = () => {
    Cookies.remove("google_info");
    Cookies.remove("user_name");
    Cookies.remove("user_id");
    Cookies.remove("email");
    handleMenuClose();
    setLoggedInUserDetails(Object.assign({}));
  };

  const toggleSidebarInMobile = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setShowSidebarInMobile(open);
  };

  // call this when the modal opens so
  // this initiates the payment and shows the image
  const reconcialationPaymentStatus = (merchantTransactionId) => {
    // generate all the times to hit payment status api

    // The first status check at 20-25 seconds post transaction start
    let times = [20000];

    // Every 3 seconds once for the next 30 seconds
    for (let i = 0; i < 10; i++) {
      times.push(times[i] + 3000);
    }
    // Every 6 seconds once for the next 60 seconds
    for (let i = 10; i < 20; i++) {
      times.push(times[i] + 6000);
    }

    // // Every 10 seconds for the next 60 seconds
    for (let i = 20; i < 26; i++) {
      times.push(times[i] + 10000);
    }

    //  Every 30 seconds for the next 60 seconds
    for (let i = 26; i < 28; i++) {
      times.push(times[i] + 30000);
    }

    // Every 1 min until timeout (15 mins)
    for (let i = 28; i < 43; i++) {
      times.push(times[i] + 60000);
    }

    let timeoutIDs = [];
    for (let time of times) {
      let timeoutID = setTimeout(
        async (merchantTransactionId, loggedInUserDetails) => {
          try {
            let status = await paymentStatus(merchantTransactionId, loggedInUserDetails);

            if (status.code === "PAYMENT_SUCCESS") {
              clearPaymentIntervalIds(timeoutIDs);
              handleCloseNestedPaymentModal();
              handleOpenPaymentSnackbar();
              setSnackbarStatusMessage("Payment successful");
              let temp = _.cloneDeep(loggedInUserDetails);
              handleShowBannerPremium();
              temp["premium"] = true;
              setLoggedInUserDetails(temp);
            } else if (status.code !== "PAYMENT_PENDING") {
              clearPaymentIntervalIds(timeoutIDs);
              handleCloseNestedPaymentModal();
              handleOpenPaymentSnackbar();
              setSnackbarStatusMessage("Payment failed");
            }
          } catch {
          } finally {
          }
        },
        time,
        merchantTransactionId,
        loggedInUserDetails
      );

      timeoutIDs.push(timeoutID);
    }

    setIntervalIds(timeoutIDs.slice());
  };

  const handlePayment = async (selectedUPIPayApp) => {
    try {
      setLoaderPayModal(true);

      let deviceOS;
      if (isIOS) {
        deviceOS = "IOS";
      }
      let response = await pay(loggedInUserDetails, deviceOS, selectedUPIPayApp);

      setLoaderPayModal(false);
      const merchantTransactionId = response.merchantTransactionId;
      reconcialationPaymentStatus(merchantTransactionId);
      setPayData(response);
    } catch (error) {
      setLoaderPayModal(false);
      console.log(error);
    }
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      {/* 
      <MenuItem onClick={handleMenuClose}>
        <Typography textAlign="center">My account</Typography>
      </MenuItem> */}
      {/* <MenuItem onClick={handleProfileMenuClick}>
        <Typography textAlign="center">Profile</Typography>
      </MenuItem> */}
      <MenuItem onClick={logout}>
        <Typography textAlign="center">Logout</Typography>
      </MenuItem>
    </Menu>
  );

  const handlePaymentModalOpen = async (event, selectedUPIApp) => {
    // if logged in then only show the buy banner else prompt to login
    if (loggedInUserDetails.name) {
      if (isIOS && !showUPISelectScreen) {
        // open the UPI select screen if it isn't already opened and if it is IOS
        handleOpenUPISelectScreen();
      } else if (isIOS && showUPISelectScreen) {
        // handleCloseUPISelectScreen();
        handlePayment(selectedUPIApp);
        handleMobileMenuClose();
      } else {
        handlePayment();
        setPaymentModalOpen(true);
        handleMobileMenuClose();
      }
    } else {
      setLoginPrompt(true);
    }
  };

  const clearPaymentIntervalIds = (timeoutIDs) => {
    for (let id of timeoutIDs) {
      // console.log("Auto Clearing id of: ", id);
      clearTimeout(id);
    }
  };

  const handlePaymentModalClose = () => {
    // setIntervalIds([]);
    setPaymentModalOpen(false);
    // console.log("I run");
  };

  const handleOpenNestedPaymentModal = (event) => {
    setPaymentModalNestedOpen(true);
  };

  const handleCloseNestedPaymentModal = (event) => {
    for (let intervalId of intervalIds) {
      clearInterval(intervalId);
    }
    setPaymentModalNestedOpen(false);
    handlePaymentModalClose();
    handleCloseUPISelectScreen();
  };

  const clearPayIntervals = () => {
    for (let intervalId of intervalIds) {
      clearInterval(intervalId);
    }
  };

  const handleCloseNestedPaymentModalOnly = (event) => {
    setPaymentModalNestedOpen(false);
  };

  const handleLoginButtonPrompt = () => {
    navigate("/login", { state: { from: window.location.pathname } });
  };

  const handleRemoveBanner = () => {
    setBannerData({
      showBanner: "false",
    });

    localStorage.setItem("showBanner", false);
  };
  const handleShowBannerPremium = () => {
    /**Duplicate code but withour set local storage
     *
     */
    setBannerData({
      showBanner: "false",
    });
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {!loggedInUserDetails.premium ? (
        <MenuItem>
          <ListItem onClick={handlePaymentModalOpen} sx={{ color: "black" }}>
            Buy
          </ListItem>
        </MenuItem>
      ) : null}
      <MenuItem>
        <Link
          href={`${isMobile ? "mailto:admin@prepifai.com" : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=admin@prepifai.com"}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: "black", fontSize: "1rem", textDecoration: "none" }}>
          Contact Us
        </Link>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large" edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit" sx={{ marginRight: 1 }}>
          <Avatar src={loggedInUserDetails.picture} sx={{ width: 31, height: 31 }} />
        </IconButton>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        {/* <AppBar position="fixed" sx={{ p: 1 }} style={{ background: "black" }}> */}
        <PaymentDialogParent
          paymentModalOpen={paymentModalOpen}
          handlePaymentModalOpen={handlePaymentModalOpen}
          payData={payData}
          loaderPayModal={loaderPayModal}
          handleCloseNestedPaymentModal={handleCloseNestedPaymentModal}
          handleOpenNestedPaymentModal={handleOpenNestedPaymentModal}
          handleCloseNestedPaymentModalOnly={handleCloseNestedPaymentModalOnly}
          paymentModalNestedOpen={paymentModalNestedOpen}
          showUPISelectScreen={showUPISelectScreen}
          handleOpenUPISelectScreen={handleOpenUPISelectScreen}
          handleCloseUPISelectScreen={handleCloseUPISelectScreen}
          setSelectedUPIApp={setSelectedUPIApp}
          selectedUPIApp={selectedUPIApp}
          clearPayIntervals={clearPayIntervals}
        />
        <BootstrapDialog onClose={handleLoginPromptClose} aria-labelledby="login prompt" open={openLoginPrompt}>
          <BootstrapDialogTitle id="login with your google account" onClose={handleLoginPromptClose}>
            Login for this feature
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>Login to get access to this feature and many more...</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleLoginButtonPrompt}>
              Login
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <PaymentSnackbar
          snackbarStatusMessage={snackbarStatusMessage}
          open={showPaymentSnackbar}
          handleClosePaymentSnackbar={handleClosePaymentSnackbar}
        />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, p: Number(`${bannerData.showBanner === "true" ? 0 : 1}`) }}
          style={{
            background: "#fff",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}>
          {bannerData.showBanner === "true" ? (
            <HeaderBanner handleRemoveBanner={handleRemoveBanner} handlePaymentModalOpen={handlePaymentModalOpen} />
          ) : null}
          {/* <Toolbar>
                        <Box sx={{ flexGrow: 1, p: 0 }}>
                            <HeaderBanner />
                        </Box>
                    </Toolbar> */}
          {/* {bannerData.showBanner ? <HeaderBanner handleRemoveBanner={handleRemoveBanner} /> : null} */}
          <Toolbar>
            {/* <Typography
                        variant="h4"
                        noWrap
                        component="div"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            color: 'black',
                            textDecoration: 'none',
                        }}
                        >
                        Collector Babu
                    </Typography> */}
            <Box sx={{ flexGrow: 0, display: "flex" }}>
              <img src="/images/mainLogo.png" alt="main logo" width="150px" height="80px" />
            </Box>
            <Box sx={{ flexGrow: 1, display: "flex" }}></Box>
            {loggedInUserDetails.name ? (
              <>
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                  <List style={{ display: "flex" }}>
                    {!loggedInUserDetails.premium ? (
                      <ListItem disablePadding onClick={handlePaymentModalOpen}>
                        <ListItemButton sx={{ textAlign: "center", color: "black" }}>
                          <ListItemText primary={"Buy"} />
                        </ListItemButton>
                      </ListItem>
                    ) : null}
                    <ListItem disablePadding>
                      <ListItemButton sx={{ textAlign: "center" }}>
                        <Link
                          href={`${isMobile ? "mailto:admin@prepifai.com" : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=admin@prepifai.com"}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "black", textDecoration: "none" }}>
                          Contact Us
                        </Link>
                      </ListItemButton>
                    </ListItem>
                  </List>
                  <Tooltip title="Open settings">
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                      sx={{ marginRight: 1 }}>
                      <Avatar src={loggedInUserDetails.picture} sx={{ width: 31, height: 31 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {
                  // shows appbar in small screen devices
                }
                <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" }, alignItems: "center" }}>
                  {!loggedInUserDetails.premium ? (
                    <Button style={{ color: "white", backgroundColor: "black" }} onClick={handlePaymentModalOpen}>
                      Buy
                    </Button>
                  ) : null}
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit">
                    <MoreIcon style={{ color: "black" }} />
                  </IconButton>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                  <List style={{ display: "flex" }}>
                    {!loggedInUserDetails.premium ? (
                      <ListItem disablePadding onClick={handleLoginPromptOpen}>
                        <ListItemButton sx={{ textAlign: "center", color: "black" }}>
                          <ListItemText primary={"Buy"} />
                        </ListItemButton>
                      </ListItem>
                    ) : null}
                    <ListItem disablePadding>
                      <ListItemButton sx={{ textAlign: "center" }}>
                        <Link
                          href={`${isMobile ? "mailto:admin@prepifai.com" : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=admin@prepifai.com"}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "black" }}>
                          Contact Us
                        </Link>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
                {
                  // TODO: Duplicated code below handle this better
                  // Context: mobile logged out view show login button prompt
                }
                <Box sx={{ flexGrow: 0, display: { xs: "flex" }, alignItems: "center" }}>
                  <Button style={{ color: "white", backgroundColor: "black" }} onClick={handleLoginButton}>
                    Login
                  </Button>
                </Box>
              </>
            )}
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Drawer
          variant={`${matches ? "temporary" : "permanent"}`}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              borderColor: "#fff",
            },
          }}
          anchor="left"
          open={showSidebarInMobile}
          onClose={toggleSidebarInMobile(false)}>
          {/* <HeaderBanner /> */}
          <Toolbar />
          <Box
            sx={{
              overflow: "auto",
              // mt: 6,
              mt: Number(`${bannerData.showBanner === "true" ? (matches ? 14 : 9.5) : 5.5}`),
            }}>
            <List sx={{}}>
              <ListItem key={"aiims-pg-pyq-previous-year-questions"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  data-text="aiims-pg-pyq-previous-year-questions"
                  data-index={0}
                  onClick={handleDrawerIconNavigate(0)}
                  selected={sidebarData["selectedIndex"] === 0}>
                  <ListItemIcon data-text={"aiims-pg-pyq-previous-year-questions"}>
                    <SpeedIcon
                      fontSize="large"
                      sx={{
                        color: `${sidebarData["selectedIndex"] === 0 ? selectedIconColor : unselectedIconColor}`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"PG PYQ"}
                    sx={{
                      color: `${sidebarData["selectedIndex"] === 0 ? selectedTabColor : unselectedIconColor}`,
                    }}
                    data-text={"aiims-pg-pyq-previous-year-questions"}
                  />
                </ListItemButton>
              </ListItem>
              {/* <ListItem key={"NEET-pg-pyq-previous-year-questions"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  data-text="NEET-pg-pyq-previous-year-questions"
                  data-index={1}
                  onClick={handleDrawerIconNavigate(1)}
                  selected={sidebarData["selectedIndex"] === 1}>
                  <ListItemIcon data-text={"NEET-pg-pyq-previous-year-questions"}>
                    <SpeedIcon
                      fontSize="large"
                      sx={{
                        color: `${sidebarData["selectedIndex"] === 1 ? selectedIconColor : unselectedIconColor}`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"NEET PG PYQ"}
                    sx={{
                      color: `${sidebarData["selectedIndex"] === 1 ? selectedTabColor : unselectedIconColor}`,
                    }}
                    data-text={"NEET-pg-pyq-previous-year-questions"}
                  />
                </ListItemButton>
              </ListItem> */}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, mt: Number(`${bannerData.showBanner === "true" ? (matches ? 14 : 4) : 0}`) }}>
          <Toolbar />
          {matches && showMobileFABMenu ? (
            <Fab
              variant="extended"
              style={{
                right: 20,
                bottom: 20,
                position: "fixed",
                backgroundColor: "#0288d1",
              }}
              onClick={toggleSidebarInMobile(!showSidebarInMobile)}>
              <NavigationIcon sx={{ mr: 1 }} />
              Menu
            </Fab>
          ) : null}
          <Component
            handlePaymentModalOpen={handlePaymentModalOpen}
            setShowMobileFABMenu={setShowMobileFABMenu}
            handleShowBannerPremium={handleShowBannerPremium}
          />
        </Box>
      </Box>
    </>
  );
};

export default LeftPane;
