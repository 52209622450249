import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";

const ConfirmationExit = ({ closeAllModals, handleCloseNestedPaymentModalOnly, paymentModalNestedOpen }) => {
  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 235 } }} maxWidth="xs" open={paymentModalNestedOpen} keepMounted>
      <DialogTitle>Are you sure you want to close? This might affect your payment if it is already in progress.</DialogTitle>
      <DialogActions>
        <Button onClick={closeAllModals}>Yes</Button>
        <Button onClick={handleCloseNestedPaymentModalOnly}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationExit;
