import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ConfirmationExit from "./ConfirmationExit";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

const UPI_APPS = ["/images/payment_screen/gpay.jpg", "/images/payment_screen/paytm.jpeg", "/images/payment_screen/phonepe.jpeg"];

const selected = `"boxShadow": "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"`;

const UPISelectScreen = ({
  showUPISelectScreen,
  handleCloseUPISelectScreen,
  handlePaymentModalOpen,
  handleCloseNestedPaymentModal,
  handleCloseNestedPaymentModalOnly,
  paymentModalNestedOpen,
  handleOpenNestedPaymentModal,
  setSelectedUPIApp,
  loaderPayModal,
  payData,
  clearPayIntervals,
  selectedUPIApp,
}) => {
  return (
    <Dialog maxWidth="lg" open={showUPISelectScreen} aria-labelledby="Select UPI Screen" aria-describedby="Select your UPI app" keepMounted>
      <DialogTitle id="UPI-Select-Screen">
        <Alert severity="warning" sx={{ mb: 3 }}>
          Please don't close or refresh while payment is processing. It will close automatically.
        </Alert>
        Pay ₹ 399 (6 months)
        <br />
        Select your UPI App
        <IconButton
          aria-label="close"
          onClick={handleOpenNestedPaymentModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2, mb: 2 }}>
        <div
          style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", gap: "20px", marginTop: "10px" }}>
          {UPI_APPS.map((path) => {
            return (
              <Button
                data-path={path}
                onClick={(event) => {
                  let selectedUPIApp = path.substring(path.lastIndexOf("/") + 1, path.lastIndexOf(".")).toUpperCase();
                  handlePaymentModalOpen(event, selectedUPIApp);
                  clearPayIntervals();
                }}>
                <img src={path} alt={path} width="50px" height="60px" />
              </Button>
            );
          })}
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
          {loaderPayModal ? (
            <Typography variant="h6">Loading...please wait</Typography>
          ) : payData.intentUrl && !loaderPayModal ? (
            <Button variant="contained" style={{ marginTop: "20px" }}>
              <a href={`${payData.intentUrl}`} alt="mobile UPI payment link" style={{ color: "white" }}>
                Click here to pay with {selectedUPIApp}
              </a>
            </Button>
          ) : null}
        </div>
      </DialogContent>
      <ConfirmationExit
        closeAllModals={handleCloseNestedPaymentModal}
        handleCloseNestedPaymentModalOnly={handleCloseNestedPaymentModalOnly}
        paymentModalNestedOpen={paymentModalNestedOpen}
      />
    </Dialog>
  );
};

export default UPISelectScreen;
