import { Interweave } from "interweave";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  CardActions,
  Stack,
  Button,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Option from "./Option";
import { memo, useState } from "react";
import ReportQuestion from "../ReportQuestion/ReportQuestion";
import ShareIcon from "@mui/icons-material/Share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@mui/material/Snackbar";

// incase more options than 4, defensive handling
const choicesList = ["A", "B", "C", "D", "E", "F", "G"];

// TODO: Add Subject in the exam mode

const StyledListItemButton = styled(ListItem)(({ listtitle }) => ({
  "&::before": {
    content: `"${listtitle}"`,
    position: "absolute",
    top: "-18px",
    left: "5px",
    padding: "",
    textAlign: "center",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return (
    <Typography paragraph color="text.secondary" sx={{ marginLeft: "8px" }}>
      Detailed Solution
      <IconButton {...other} />
    </Typography>
  );
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ShareButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#1e293b",
  "&:hover": {
    backgroundColor: "#020617",
  },
}));

const PYQFastTrackCard = ({
  data,
  handleOptionSelect,
  cardIndex,
  selectedQuestion,
  isWrongMarkedQuestion,
  examSubmitted,
  defaultExpand,
  totalQuestions,
  isRepeated,
}) => {
  // question data
  const { question, options, correctChoiceIndex, selectedOption, detailed_solution, yearAsked, _id, parentExam } = data;
  const [expanded, setExpanded] = useState(defaultExpand);
  const [openShareQuestion, setShareQuestion] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleShareQuestion = (event) => {
    setShareQuestion(true);
  };

  const handleShareQuestionClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShareQuestion(false);
  };

  return (
    <Card variant="outlined" sx={{ mb: 1 }}>
      <Snackbar open={openShareQuestion} autoHideDuration={6000} onClose={handleShareQuestionClose} message="Link Copied">
        <Alert serverity="success" elevation={6} variant="filled">
          Link copied, you can paste the question now and share it with your friends
        </Alert>
      </Snackbar>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: 14, textAlign: "start" }} color="text.secondary" gutterBottom>
            Question {cardIndex + 1} of {totalQuestions} {isRepeated ? "- Repeatead Question" : null}
          </Typography>
          <CopyToClipboard text={`https://prepifai.com/questions/${_id}`} onCopy={handleShareQuestion}>
            <ShareButton style={{ fontSize: "14px", fontColor: "#fff" }} sx={{ p: 1 }} startIcon={<ShareIcon />}>
              Share Question
            </ShareButton>
          </CopyToClipboard>
          <ReportQuestion questionId={_id} parentExam={parentExam} />
        </Stack>
        <Typography variant="h6" component="div" sx={{ textAlign: "start" }} className="mb-3">
          <Interweave content={question}></Interweave>
        </Typography>
        <List>
          {options.map((option, index) => {
            let outlineText = "";
            let backgroundColorAfterSubmit = "";
            if (examSubmitted) {
              if (index === selectedOption && correctChoiceIndex === selectedOption) {
                outlineText = "Selected & Correct Answer";
                backgroundColorAfterSubmit = "bg-green-200";
              } else if (index === selectedOption && correctChoiceIndex !== selectedOption) {
                outlineText = "You Selected";
                backgroundColorAfterSubmit = "bg-red-200";
              } else if (index === correctChoiceIndex) {
                outlineText = "Correct Answer";
                backgroundColorAfterSubmit = "bg-green-200";
              } else if (index === selectedOption) {
                outlineText = "Selected Answer";
                backgroundColorAfterSubmit = "bg-sky-50";
              } else if (index === correctChoiceIndex && selectedOption === -1) {
                outlineText = "Not Attempted Correct Answer";
                backgroundColorAfterSubmit = "bg-green-200";
              }
            } else if (index === selectedOption) {
              backgroundColorAfterSubmit = "bg-sky-50";
            }
            return (
              <StyledListItemButton
                disablePadding
                key={option}
                className={`${backgroundColorAfterSubmit} ${index === options.length - 1 ? "mb-0" : "mb-3"} py-1`}
                onClick={handleOptionSelect}
                data-cardindex={cardIndex}
                data-optionindex={index}
                data-iswrongmarkedquestion={isWrongMarkedQuestion}
                listtitle={outlineText}>
                <ListItemButton>
                  <Option alphabet={choicesList[index]} selected={index === selectedOption ? true : false} />
                  <ListItemText primary={<Interweave content={option} />} />
                </ListItemButton>
              </StyledListItemButton>
            );
          })}
        </List>
      </CardContent>
      {examSubmitted ? (
        <>
          <CardActions disableSpacing>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                <Interweave content={detailed_solution}></Interweave>
              </Typography>
            </CardContent>
          </Collapse>
        </>
      ) : null}
    </Card>
  );
};

export default PYQFastTrackCard;
