const endpoint = "https://prepifai.com/api";

const apiData = {
  getWidgetContent: endpoint + "/widget/content",
  getWidgetContentDetails: endpoint + "/content_details",
  editWidgetSource: endpoint + "/widget/edit_source",
  getWidgetNames: endpoint + "/widget/list",
  userWidgetAction: endpoint + "/user/user_widget_action",
  getUserRemovedRules: endpoint + "/user/user_removed_rules",
  performUserWidgetAction: endpoint + "/user/user_widget_action",
  getUserWidgetActions: endpoint + "/user/get_user_widget_actions",
  undoUserWidgetActions: endpoint + "/user/undo_user_widget_actions",
  loginViaSso: endpoint + "/user/login_via_sso",
  pay: endpoint + "/payment/pay",
  paymentStatus: endpoint + "/payment/status",
};

export { apiData };
