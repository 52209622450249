import { useEffect, useState, useContext } from "react";
import { CircularProgress, Box, Typography, Grid, Paper, Stack, Alert, Link } from "@mui/material";
import LeftPane from "../LeftPane/LeftPane";
import axios from "axios";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import UserContext from "../../UserContext";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import StickyFooter from "../StickyFooter/StickyFooter";
import _ from "lodash";
import { useParams } from "react-router-dom";
import DisplayQuestionCard from "./DisplayQuestionCard";

const QuestionsDashboard = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);

  useEffect(() => {
    isLoggedIn();
  }, []);

  const isLoggedIn = () => {
    let name = Cookies.get("user_name");
    let user_id = Cookies.get("user_id");
    let email = Cookies.get("email");

    if (name && user_id && email) {
      let apiData = {
        name: name,
        user_id: user_id,
        email: email,
        domain: "prepifai",
      };

      internalLogin(apiData, undefined, false);
    }
  };

  const internalLogin = (data, userInfo, setCookie = false) => {
    let config = {
      method: "post",
      url: "https://prepifai.com/api/user/login_via_sso",
      headers: {
        token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        // match user_if from response with the data stored in cookie
        if (response.data.user_id === data.user_id) {
          setLoggedInUserDetails(Object.assign({}, JSON.parse(Cookies.get("google_info")), { user_id: data.user_id }));
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <Box sx={{ display: "flex", ml: 4, flexDirection: "column", minHeight: "100vh" }}>
        <LeftPane Component={Questions} />
      </Box>
      <StickyFooter />
    </>
  );
};

const Questions = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [openLoginPrompt, setLoginPrompt] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Questions");
  const [question, setQuestion] = useState({});
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [titleTag, setTitleTag] = useState(null);
  let { slug } = useParams();
  let navigate = useNavigate();
  slug = slug.split("-")[0];

  useEffect(() => {
    // get object id from use params
    try {
      setQuestionsLoading(true);
      gaEventTracker(`Questions`);
      // make api call
      getQuestionData();
      setQuestionsLoading(false);
    } catch (error) {
      setQuestionsLoading(false);
    }
  }, []);

  useEffect(() => {
    // let parentExam = question?.parentExam;
    // let yearAsked = question?.yearAsked;
    // let question = cleanHTML(question?.question);
    // let options = cleanHTML(question?.options.join(""));
    // let idk = question.options.join(" ")
    // console.log("SSSSSSSSS", question);
    setTitleTag(cleanHTML(`${question.parentExam} - ${question.subject} - ${question.yearAsked} - ${question.question} - ${question?.options}`));
    // setTitleTag(question.options);
  }, [question]);

  const getQuestionData = async () => {
    let body = {
      question_id: slug,
    };

    let data = await axios.post("https://prepifai.com/api/pyq/get_question", body, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
      },
    });

    setQuestion(data.data);
  };

  const handleOptionSelect = (event) => {
    let selectedOption = Number(event.currentTarget.dataset.optionindex);

    let tempQuestionsState;
    tempQuestionsState = modifySelectedOptionFromState(question, selectedOption);
    setQuestion(tempQuestionsState);
    setSubmitted(true);
  };

  const cleanHTML = (content) => {
    if (content) {
      content = content.replace(/<[^>]*>/g, " ");
      content = content.replace(/\s+/g, " ");
    }
    return content;
  };

  const modifySelectedOptionFromState = (question, selectedOption) => {
    let tempQuestionsState = _.cloneDeep(question);
    tempQuestionsState["selectedOption"] = Number(selectedOption);

    return tempQuestionsState;
  };

  const navigateToFastTrack = () => {
    navigate("/aiims-pg-pyq-previous-year-questions", { state: { from: window.location.pathname } });
  };

  return (
    <>
      <Helmet>
        <title>{titleTag}</title>
        <meta
          name="keywords"
          content="AIIMS PG NEET PG Previous Year Question Anaesthesia Anatomy Biochemistry Dermatology ENT Forensic Medicine Microbiology Obstetrics and Gynaecology Ophthalmology Orthopaedics PSM Paediatrics Pathology Pharmacology Physiology Psychiatry Radiology Surgery"
        />
        <meta name="description" content={titleTag} />

        <meta property="og:title" content={titleTag} />
        <meta property="og:url" content="https://prepifai.com/pyq-fast-track" />
        <meta property="og:description" content={titleTag} />
        <meta property="og:site_name" content="Prepif AI" />
      </Helmet>
      <Box component="main" sx={{ mt: 7, ml: 1, mr: 2, mb: 4 }} display="flex" justifyContent="center">
        <Grid container rowSpacing={3} columnSpacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
          <Grid item xs={12} align="center" justify="center" alignItems="center" sx={{ justifySelf: "start" }}>
            {questionsLoading ? null : (
              <Typography variant="h1" sx={{ fontSize: "3rem" }}>
                {question.parentExam} - {`${question.yearAsked ? question.yearAsked + " - " : ""}`} {`${question.subject ? question.subject : ""} `}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} align="center" justify="center" alignItems="center">
            {questionsLoading ? (
              <Stack direction="column" justifyContent="center" alignItems="center">
                <CircularProgress color="info" />
              </Stack>
            ) : (
              <Paper elevation={0} spacing={2}>
                {Object.keys(question).length > 0 ? (
                  <DisplayQuestionCard
                    parentExam={question.parentExam}
                    subject={question.subject}
                    data={question}
                    handleOptionSelect={handleOptionSelect}
                    submitted={submitted}
                    defaultExpand={false}
                  />
                ) : null}
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} align="center" justify="center" alignItems="center" sx={{ justifySelf: "start" }}>
            <Alert severity="info">
              Practice more Previous Year Questions (PYQ) on{" "}
              <Link style={{ cursor: "pointer" }} onClick={navigateToFastTrack}>
                https://prepifai.com/pyq-fast-track
              </Link>
            </Alert>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default QuestionsDashboard;
